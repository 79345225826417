import React from 'react';
import { useQuery } from 'react-query';
import { adminLocalizationApi, adminLocalizationKeys } from '../../../api/admin/localization';
import { Typography, Button, Card } from 'antd';
import { Container, PageContainer } from '../../../ui/layout';
import { history } from '../../../routing';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const Editor = () => {
  const { t } = useTranslation();
  const { data } = useQuery({
    queryKey: adminLocalizationKeys.getAllQuestionnaires,
    queryFn: adminLocalizationApi.getAllQuestionnaires,
    select: data1 => data1.data
  });

  return (
    <PageContainer>
      <Container paddingY={24}>
        <Typography.Title level={3}>{t('Editor')}</Typography.Title>
      </Container>

      <RowCards>

      {data &&
      data?.map((quest) => (
        <Card title={quest.type} size='small'>
          {quest.name && <Typography.Paragraph>{quest.name}</Typography.Paragraph>}
          <Typography.Text>{quest.title}</Typography.Text>

          <Container marginTop={12}>
            <Button
              mode={'strong'}
              onClick={() => history.push(`/editor/${quest.id}`)}
              type='primary'
            >
              Edit
            </Button>
          </Container>
        </Card>
      ))}
      </RowCards>
    </PageContainer>
  );
};

const RowCards = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;